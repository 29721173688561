import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../../components/layout';

const RodasDeLeiturasPage = ({data, ...props}) => {
  const pageTitle = "Rodas de Leituras";
  const keywords = "Rodas de Leituras, Leituras no Litoral";
  const description = "Rodas de Leituras do projeto Leituras no Litoral";

  const hasEvents = false;

  const events = () => {
    return (
      <div>
        {
          categoryDescription()
        }

        <div className='row u-margin-top'>
          {
            data.allMdx.nodes.map((node) => {
              const image = getImage(node.frontmatter.hero_image);
              const imageAlt = node.frontmatter.hero_image_alt;
              const to = `/rodas-de-leituras/${node.slug}`;
              const title = node.frontmatter.title;
              const date = node.frontmatter.date || '';
              const time = node.frontmatter.time || '';
              const location = node.frontmatter.location;
              const classification = node.frontmatter.classification;

              return (
                <div className='col-xs-12 col-sm-6' key={node.id}>
                  <article className='event-card'>
                    <Link
                      to={to}
                      title={title}
                    >
                      <div className='event-card__img'>
                        <GatsbyImage
                          image={image}
                          alt={imageAlt}
                          title={imageAlt}
                        />
                      </div>

                      <div className='event-card__body'>
                        <h2>
                          {title}
                        </h2>

                        <ul className='u-reset-list'>
                          {
                            date && date !== 'Data inválida'
                              ? <li><strong>Data:</strong> {date}</li>
                              : null
                          }
                          {
                            time
                              ? <li><strong>Horário:</strong> {time}</li>        
                              : null
                          }
                          <li><strong>Local:</strong> {location}</li>
                          <li><strong>Classificação indicativa:</strong> {classification}</li>
                        </ul>

                      </div>
                    </Link>
                  </article>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  };

  const categoryDescription = () => {
    return (
      <div className='row'>
        <div className='col-xs-12'>
          <div className='content-card u-margin-top'>
            <div className='content-card__body'>
              <h2>Sobre</h2>

              <p>As rodas de leituras serão mediadas por <strong>Manu Assini</strong> em escolas estaduais e divididas em 50 encontros.</p>

              <p>Estas ações se desenvolverão da seguinte forma: em círculo, cada participante recebe uma cópia da história e acompanha a leitura realizada pela mediadora. Em seguida, esta <strong>estimula os participantes a exporem suas leituras, impressões, sensações, contradições, advindas da experiência literária em um espaço de debates e reflexões</strong>. </p>
            </div>
          </div>

          <div className="wave wave--left wave--2"></div>

          <div className='content-card u-margin-top'>
            <div className='content-card__body'>
              <h2>Manu Assini</h2>

              <p>Manu Assini é artista visual, cenógrafa, figurinista, aderecista e professora. Formada na Faculdade de Artes do Paraná em Licenciatura em Artes Visuais (2007), sempre desenvolveu seu trabalho artístico ligado de forma direta ou indireta ao ensino.</p>

              <p>Assim como educadora na sua formação profissional e pessoal, a mediação de leitura também sempre esteve presente nessa trajetória. Como mediadora de leitura e coordenadora da Casa da Leitura Wilson Bueno da Fundação Cultural de Curitiba (2012 – 2015) desenvolveu vários projetos voltados para a mediação literária e visual, arriscando explorar outras formas de leitura.  </p>

              <p>Também desenvolve um trabalho voltado para pintura, pintura/instalação e escultura. Sua pesquisa artística é focada na Mitologia Africana, buscando nas histórias a linguagem para o seu trabalho. Participou de salões de arte, assim como foi selecionada recentemente na 1º Bienal Black Brazil, com o seu trabalho -  ”Obá”, 2019.</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout
      pageTitle={pageTitle}
      keywords={keywords}
      description={description}
      {...props}
    >
      {
        hasEvents ? events() : categoryDescription()
      }
    </Layout>
  )
};

// export const query = graphql`
//   query {
//     allMdx(
//       sort: {fields: frontmatter___date, order: ASC}
//       filter: {fileAbsolutePath: {regex: "/(rodas-de-leituras)/"}}
//     ) {
//       nodes {
//         frontmatter {
//           title
//           time
//           location
//           classification
//           date(formatString: "DD MMMM, YYYY", locale: "PT-BR")
//           hero_image {
//             childImageSharp {
//               gatsbyImageData(
//                 width: 700
//                 aspectRatio: 1.33
//                 transformOptions: {
//                   fit: COVER
//                   cropFocus: ATTENTION
//                 }
//               )
//             }
//           }
//           hero_image_alt
//         }
//         id
//         slug
//       }
//     }
//   }
// `;

export default RodasDeLeiturasPage;